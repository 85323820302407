import request from './services/axios.js'

// 期刊详情相关接口
// 期刊详情查询
export function GetMagDetail(params) {
  return request({
    params,
    url: '/vas/mag/get',
    method: 'get'
  })
}
// 获取期刊统计概览信息
export function GetMagOverView(params) {
  return request({
    params,
    url: '/vas/mag/getMagOverView',
    method: 'get'
  })
}
